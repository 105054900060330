.header {
  &-group {
    background: color(background-header);
  }

  display: flex;

  padding: rem(10) 0;

  text-align: center;

  align-items: center;
  flex-direction: column;

  @include breakpoint($tablet) {
    flex-direction: row;

    text-align: inherit;
  }

  .logo {
    font-size: rem(50);
    font-weight: 700;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    color: $color-white;
    text-transform: uppercase;
    letter-spacing: rem(1);

    background-image: url('/images/fire.jpeg');
  }

  &__content {
    display: flex;

    align-items: center;

    @include breakpoint($tablet) {
      margin-left: auto;
    }
  }


}
