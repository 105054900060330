button,
.button {
  display: inline-flex;

  padding: rem(5) rem(10);

  justify-content: center;
  align-content: center;
  align-items: center;

  cursor: pointer;

  transition: background-color 0.2s linear, color 0.2s linear;
  border: none;

  &--outline {
    color: var(--text);

    border: rem(1) solid var(--text);
    border-radius: rem(4);
    background: transparent;
  }

  &--big {
    padding: rem(15) rem(30);

    color: var(--text);
    font-size: rem(20);

    border-radius: rem(5);

    background-color: #e12a2a;

    &:hover {
      background-color: darken(#e12a2a, 10%);
    }
  }
}
