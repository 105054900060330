.wheel__form {
  margin-top: rem(30);

  .form-label {
    display: inline-block;

    margin-bottom: rem(5);

    font-weight: 700;
    letter-spacing: rem(1);
  }

  .form-widget {
    input {
      height: rem(35);

      padding: rem(3) rem(7);
      margin-bottom: rem(10);
    }

    button {
      @extend .button;
      @extend .button--outline;

      height: rem(35);

      color: var(--text);

      background: transparent;

    }
  }

  #wheel_choices {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: rem(320);

    .form-label {
      display: none;
    }

    .form-widget {
      display: flex;

      button {
        margin-left: rem(10);
      }
    }
  }

  button[type="submit"] {
    @extend .button;
    width: 100%;
    height: rem(50);

    margin-top: rem(15);

    font-weight: 700;
    letter-spacing: rem(1);
    color: $color-white;

    background-color: $color-success;
    border: rem(1) solid $color-success;
    border-radius: rem(4);

    &:hover {
      background-color: darken($color-success, 10%);
    }
  }
}

