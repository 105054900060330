.footer {
  &-group {
    display: flex;

    padding: rem(20) 0;
  }

  display: flex;

  flex-direction: column;
}
