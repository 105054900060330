:root {

  /* prevents mobile browsers from sometimes scaling text */
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  display: flex;
  min-height: 100vh;

  flex-direction: column;

  font-family: $font-main;
  line-height: 1.5;
  color: color(text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  background-image: url("/images/background-pattern.svg");

  scroll-behavior: smooth;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container {
  display: flex;
  width: 100%;
  max-width: calc(1575px - 25px); // 1500px

  margin: 0 auto;
  padding: 0 rem(25);

  flex-direction: column;

  &--small {
    max-width: rem(500);
  }
}

.hide {
  display: none;
}

section {
  display: flex;

  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;

  list-style: none;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;

  transition: color 0.2s linear;

  // &:hover {
  //   color:
  // }
}

@keyframes rotate-full {
  0%    { transform: rotate(0) }

  100%  { transform: rotate(360deg) }
}
@keyframes rotate-end {
  0%    { transform: rotate(0) }

  100%  { transform: rotate(var(--wheel-animation-end-angle)) }
}

.rotate-full {
  animation-name: rotate-full;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rotate-end {
  animation-name: rotate-end;
  animation-duration: var(--wheel-animation-end-duration);
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
