.wheel {
  display: flex;

  padding: rem(30) rem(0);

  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin: 0 auto rem(20);
  }

  &-group {
    margin: 0 auto;

    position: relative;
    z-index: 1;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;

      position: absolute;
      top: 50%;
      right: -9px;

      transform: translateY(-50%);

      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-right: 40px solid #fff;
    }
  }

  &-container {
    display: flex;
    width: 100%;
    max-width: rem(500);
    max-height: rem(500);
    aspect-ratio: 1/1;

    position: relative;
  }

  &__button-group {
    display: flex;
    width: 100%;

    z-index: 2;

    justify-content: center;
  }

  &__form-group {
    margin: 0 auto;
    padding-top: rem(30);
  }

  &-result-container {
    display: flex;
    width: 100%;

    padding: rem(20);

    flex-direction: column;

    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    transform: translate(-50%, -50%);

    background: rgba(0, 0, 0, 0.7);

    h1 {
      letter-spacing: rem(1);

      span {
        display: inline-block;
        padding: 0 rem(5);
      }

      img {
        width: rem(50);
        height: auto;
      }
    }

    .input-group {
      display: flex;
      margin-top: rem(15);

      align-items: center;
      justify-content: center;
      gap: rem(10);

      input {
        flex: 1;
        min-height: rem(32);
      }
    }

    .wheel-result-text {
      //margin-bottom: rem(15);
    }
  }
}
